@import "layouts/index";
@import "base/index";
@import "components/index";
@import "pages/index";
@import "dark/index";

.tdnowrap {
    white-space: nowrap;
}

.ant-picker {
    width: 100%;
}

.headerDisplayName {
    background-color: rgb(248, 241, 241);
    color: white !important;
    font-weight: bolder;
    border-radius: 10px;
    text-align: center;
}

.ant-modal-content {
    .ant-modal-close {
        color: #fff;
    }

    .ant-modal-header {
        background-color: @primary-1;
        color: #fff;

        .ant-modal-title {
            color: #fff;
        }
    }
}

@media(max-width:1199px) {
    .da-content-main {
        margin: 0 15px;
    }

    .ant-layout-header {
        margin-left: 15px;
        margin-right: 15px;
    }

    .ant-card-body {
        padding: 15px;
    }

    .commontable {
        .ant-table {
            .ant-table-tbody {
                tr {
                    td {
                        padding: 8px 12px;
                    }
                }
            }
        }
    }
}
.da-bgimg{
    width: 100%;
    height: 100vh;
    object-fit: cover;
}
.da-eCommerceCardOne{
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    .ant-card-body{
        width:100%
    }
}